import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';

const certNotFound = {
  exact: true,
  path: ROUTE_PATH.CERT_NOT_FOUND,
  component: Loadable(() => import('../views/certification/not-found')),
};

export default certNotFound;
