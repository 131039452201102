import React from 'react';
import {Redirect} from 'react-router-dom';
// import Loadable from 'components/Loadable';

const search = {
  path: '/search',
  component: () => <Redirect to="/coming" />,
  /*component: Loadable(() => import('../views/search')),
  routes: [
    {
      exact: true,
      path: '/search/certifications',
      component: Loadable(() => import('../views/search/certifications')),
    },
    {
      exact: true,
      path: '/search/accreditation-bodies',
      component: Loadable(() => import('../views/search/accreditation-bodies')),
    },
    {
      exact: true,
      path: '/search/certification-bodies',
      component: Loadable(() => import('../views/search/certification-bodies')),
    },
  ],*/
};

export default search;
