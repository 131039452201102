import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {useAuth} from 'contexts/AuthContext';
import {COMPANY_TYPE} from 'constants/types';

import {Button, ButtonGroup, Col, Row, FormGroup, FormText} from 'reactstrap';
import {CaptchaModal} from 'components/Captchas';
import LandingSearchHelper from './LandingSearchHelper';
import LandingSearchSelectCE from './LandingSearchSelectCE';
import LandingSearchSelectCert from './LandingSearchSelectCert';
import useGetSearchLimit from './useGetSearchLimit';

const SEARCH_TYPE = {
  ce: 'certified-entity', // default
  cert: 'certification',
};

LandingSearch.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
};

LandingSearch.defaultProps = {
  theme: 'light',
};

function LandingSearch(props) {
  const {theme, onClose} = props;
  const dark = theme === 'dark';

  const {isAuth, companyType} = useAuth();
  const isCompany = companyType === COMPANY_TYPE.company;

  const {isLoading, data, limitReached} = useGetSearchLimit(
    isAuth && isCompany
  );

  const [selected, setSelected] = useState(SEARCH_TYPE.ce);
  const [openSearchCaptcha, setOpenSearchCaptcha] = useState(false);

  function handleChangeType(e) {
    setSelected(e.target.value);
  }

  function openCaptcha() {
    setOpenSearchCaptcha(true);
  }

  function closeCaptcha() {
    setOpenSearchCaptcha(false);
  }

  return (
    <>
      {openSearchCaptcha && (
        <CaptchaModal initialCheck onClose={closeCaptcha} />
      )}

      <div className="mr-auto-2 p-lg-5">
        <h1 className={dark ? 'text-light' : 'text-primary'}>
          Who's Certified?
        </h1>

        <Row className="d-flex flex-align-start">
          <Col className="col col-12 col-md-auto mb-3">
            <ButtonGroup className="w-100">
              <Button
                color="white"
                className={
                  selected === SEARCH_TYPE.ce
                    ? 'border-secondary text-primary'
                    : ''
                }
                onClick={(e) => handleChangeType(e)}
                value={SEARCH_TYPE.ce}
              >
                Company name
              </Button>

              <Button
                color="white"
                className={
                  selected === SEARCH_TYPE.cert
                    ? 'border-secondary text-primary'
                    : ''
                }
                onClick={(e) => handleChangeType(e)}
                value={SEARCH_TYPE.cert}
              >
                Certificate number
              </Button>
            </ButtonGroup>
          </Col>

          <Col className="col flow-grow-1">
            <FormGroup onClick={openCaptcha}>
              {selected === 'certified-entity' ? (
                <LandingSearchSelectCE
                  verifyCaptcha={openCaptcha}
                  disabled={limitReached}
                  handleTooManyRequest={onClose}
                />
              ) : (
                <LandingSearchSelectCert
                  verifyCaptcha={openCaptcha}
                  disabled={limitReached}
                  handleTooManyRequest={onClose}
                />
              )}

              {limitReached ? (
                <FormText color="danger">
                  You've reached your {isCompany ? 'weekly' : 'daily'} search
                  limit!
                </FormText>
              ) : (
                <FormText>
                  <span className={cx({'text-white-70': dark})}>
                    Please ensure to enter the registered{' '}
                    {selected === SEARCH_TYPE.ce
                      ? 'company name (e.g. ABC Corporation).'
                      : 'certificate number.'}
                  </span>
                </FormText>
              )}

              {isAuth && isCompany && (
                <LandingSearchHelper
                  dark={dark}
                  isLoading={isLoading}
                  data={data}
                />
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LandingSearch;
