import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'constants/routes';

const certificationBody = {
  exact: true,
  path: ROUTE_PATH.CERTIFICATION_BODY,
  component: Loadable(() => import('../views/certification-body')),
};

export default certificationBody;
