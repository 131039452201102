import {useEffect} from 'react';
import useAlert from 'hooks/useAlert';
import useFetch from 'hooks/useFetch';
import {getSearchLimit} from 'api/search';

function useGetSearchLimit() {
  const {alertByError} = useAlert();

  const [fetch, isLoading, response] = useFetch(getSearchLimit);

  const getData = async () => {
    try {
      await fetch();
    } catch (e) {
      await alertByError(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const {data} = response;
  const {max: dataMax, try: dataTry} = data || {};
  const limitReached = data && dataMax === dataTry;

  return {isLoading, data, limitReached};
}

export default useGetSearchLimit;
