import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {Redirect} from 'react-router-dom';

const watchList = {
  path: ROUTE_PATH.DASHBOARD.SEARCH_HISTORY.INDEX,
  component: Loadable(() => import('../views/search-history')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SEARCH_HISTORY.INDEX,
      component: () => <Redirect to="/search-history/certifications" />,
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SEARCH_HISTORY.CERTIFICATIONS,
      component: Loadable(() =>
        import('../views/search-history/certifications')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SEARCH_HISTORY.CERTIFIED_ENTITIES,
      component: Loadable(() =>
        import('../views/search-history/certified-entities')
      ),
    },
  ],
};

export default watchList;
