import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'constants/routes';
import {PERMISSIONS} from 'constants/permissions';

const cbProfile = {
  permission: PERMISSIONS.CB_PROFILE.ACCESS,
  path: ROUTE_PATH.DASHBOARD.CB_PROFILE.INDEX,
  component: Loadable(() => import('../views/cb-profile')),
  routes: [
    {
      exact: true,
      permission: PERMISSIONS.CB_PROFILE.ACCESS,
      path: ROUTE_PATH.DASHBOARD.CB_PROFILE.VIEW,
      component: Loadable(() => import('../views/cb-profile/view')),
    },

    {
      exact: true,
      permission: PERMISSIONS.CB_PROFILE.EDIT,
      path: ROUTE_PATH.DASHBOARD.CB_PROFILE.EDIT,
      component: Loadable(() => import('../views/cb-profile/edit')),
    },
  ],
};

export default cbProfile;
