import Loadable from 'components/Loadable';

const elinks = {
  path: '/e',
  component: Loadable(() => import('../views/elinks')),
  routes: [
    {
      exact: true,
      path: '/e/ce/:token',
      component: Loadable(() => import('../views/elinks/confirm-email')),
    },
    {
      exact: true,
      path: '/e/inv/:token',
      component: Loadable(() => import('../views/register/invitation')),
    },
    {
      exact: true,
      path: '/e/act/:token',
      // component: () => <Redirect to="/coming" />,
      component: Loadable(() => import('../views/register/activate-token')),
    },
  ],
};

export default elinks;
