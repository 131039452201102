import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';

const certification = {
  exact: true,
  path: ROUTE_PATH.CERTIFICATION,
  component: Loadable(() => import('../views/certification')),
};

export default certification;
