import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';

const certifiedEntity = {
  exact: true,
  path: ROUTE_PATH.CERTIFIED_ENTITY,
  component: Loadable(() => import('../views/certified-entity')),
};

export default certifiedEntity;
