import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';
import {Redirect} from 'react-router-dom';

const libraries = {
  path: ROUTE_PATH.DASHBOARD.LIBRARIES.INDEX,
  permission: PERMISSIONS.LIBRARIES.ACCESS,
  component: Loadable(() => import('../views/libraries')),
  routes: [
    // List
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.INDEX,
      component: () => <Redirect to="/libraries/standard-scheme" />,
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.LIST,
      component: Loadable(() => import('../views/libraries/list')),
    },

    // IAF MLA
    // {
    //   exact: true,
    //   path: ROUTE_PATH.DASHBOARD.LIBRARIES.IAF_MLA,
    //   component: Loadable(() => import('../views/libraries/iaf-mla')),
    // },

    // Technical Sectors
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.UKAS_SECTORS,
      permission: PERMISSIONS.LIBRARIES.UKAS_SECTORS.VIEW,
      component: Loadable(() => import('../views/libraries/ukas-sectors')),
    },

    // Global Sectors
    {
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.SECTORS_LIBRARY.INDEX,
      permission: PERMISSIONS.LIBRARIES.SECTORS_LIBRARY.VIEW,
      component: Loadable(() => import('../views/libraries/global-sectors')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.SECTORS_LIBRARY.INDEX,
          permission: PERMISSIONS.LIBRARIES.SECTORS_LIBRARY.VIEW,
          component: () => <Redirect to="/libraries/sectors-library/sectors" />,
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.SECTORS_LIBRARY.SECTORS,
          permission: PERMISSIONS.LIBRARIES.SECTORS_LIBRARY.VIEW,
          component: Loadable(() =>
            import('../views/libraries/global-sectors/list')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.SECTORS_LIBRARY.GROUP,
          permission: PERMISSIONS.LIBRARIES.SECTORS_LIBRARY.VIEW,
          component: Loadable(() =>
            import('../views/libraries/global-sectors/group')
          ),
        },
      ],
    },

    // Scheme Library
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.SCHEMES,
      permission: PERMISSIONS.LIBRARIES.SCHEMES_LIBRARY.VIEW,
      component: Loadable(() => import('../views/libraries/schemes')),
    },

    // Standard Library
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.STANDARDS,
      permission: PERMISSIONS.LIBRARIES.STANDARDS_LIBRARY.VIEW,
      component: Loadable(() => import('../views/libraries/standards')),
    },

    // Standard Library
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.ANALYTICS,
      component: Loadable(() => import('../views/libraries/analytics')),
    },

    // Views
    {
      path: ROUTE_PATH.DASHBOARD.LIBRARIES.VIEWS.INDEX,
      component: Loadable(() => import('../views/libraries/views')),
      routes: [
        // View
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.VIEWS.VIEW,
          component: Loadable(() => import('../views/libraries/views/view')),
        },

        // Assigned certificates
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.LIBRARIES.VIEWS.CERTIFICATES,
          permission: PERMISSIONS.LIBRARIES.VIEWS.CERTIFICATES,
          component: Loadable(() =>
            import('../views/libraries/views/certificates')
          ),
        },
      ],
    },
  ],
};

export default libraries;
