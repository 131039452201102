import styles from './LandingSearchSelect.module.scss';
import React, {useState, useContext} from 'react';
import cx from 'classnames';

import {Link, useHistory} from 'react-router-dom';
import {debounce} from 'lodash/function';
import {components} from 'react-select';

import {addSearchAccountHistory} from 'api/search';
import {AuthContext} from 'contexts/AuthContext';

import {Select} from 'components/Selects';

import axios from 'axios';
import Cookies from 'utils/cookies';
import useAlert from 'hooks/useAlert';
import {ImageFlag} from 'components/Images';

LandingSearchSelectCE.defaultProps = {
  name: 'company_id',
  isClearable: true,
  defaultOptions: false,
  verifyCaptcha: () => {},
  size: 'md',
};

function LandingSearchSelectCE(props) {
  const {isAuth} = useContext(AuthContext);
  const {alertByError} = useAlert();
  const history = useHistory();

  const {
    verifyCaptcha,
    handleTooManyRequest,
    name,
    selected,
    disabled,
    value,
    size,
    ...rest
  } = props;
  const [keyword, setKeyword] = useState('');
  const {Option} = components;

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];
    await axios
      .get(`search/suggest-company`, {params: {q: value}})
      .then((response) => {
        const {data} = response.data;

        result = data.map((item) => {
          let obj = {};
          for (const [key, value] of Object.entries(item)) {
            obj = {company_id: key, company_name: value};
          }
          return obj;
        });
        return callback(result);
      })
      .catch((e) => {
        if (e.response.status === 429) {
          Cookies.captchaToken = '';
          alertByError(e.response.data);
          if (handleTooManyRequest) handleTooManyRequest();
        } else if (e.response.data.errors.code === 'invalid_captcha_token') {
          verifyCaptcha();
        } else {
          alertByError(e.response.data);
        }
      });
  }

  const noOptionsMessage = ({inputValue}) =>
    inputValue ? (
      <div className="font-weight-bold text-dark">
        <div className="mb-2">Can’t find the company you’re looking for?</div>
        <div>
          Click <Link to="/cert-not-found">here</Link> for further support.
        </div>
      </div>
    ) : (
      'Provide company name to search...'
    );

  function handleChange(selected) {
    if (selected) {
      const {company_id, company_name} = selected;

      if (isAuth) {
        addSearchAccountHistory({
          company_id,
          company_name,
          keyword: keyword,
          type: 'company',
        });
      }
      history.push(`/certified-entity/${company_id}`);
    }
  }

  const SuggestWithFlag = ({children, ...innerProps}) => {
    const [countryCode, companyName, companySubName] = children.split(' | ');

    return (
      <Option className="py-2 px-3 d-flex align-items-center" {...innerProps}>
        <ImageFlag className="mr-2" code={countryCode} />

        <span className="mr-1 text-dark" style={{fontSize: '1rem'}}>
          {companyName}
        </span>

        {companySubName && (
          <span className="text-muted" style={{fontSize: '0.875rem'}}>
            ({companySubName})
          </span>
        )}
      </Option>
    );
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      zIndex: 1,
      width: '100%',
      margin: '0 12px',
    }),
    input: (provided) => ({
      ...provided,
      zIndex: 2,
      width: '100%',
      margin: '0 12px',
    }),
  };

  const selectOptions = {
    className: cx(styles['landing-search__select'], {
      [styles['landing-search__select--lg']]: size === 'lg',
    }),
    loadOptions: debounce(loadOptions, 500),
    onChange: handleChange,
    getOptionLabel: (option) => option.company_name,
    getOptionValue: (option) => option.company_id,
    noOptionsMessage: noOptionsMessage,
    disabled: disabled,
    async: true,
    isLoading: false,
    placeholder: 'Search by company name',
    name: 'certified-entity',
    search: true,
    styles: customStyles,
    components: {
      Option: SuggestWithFlag,
      IndicatorSeparator: null,
      DropdownIndicator: null,
    },
    ...rest,
  };

  return <Select {...selectOptions} />;
}

export default LandingSearchSelectCE;
