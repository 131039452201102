import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const marketTrends = {
  path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.INDEX,
  permission: PERMISSIONS.MARKET_TRENDS.ACCESS,
  component: Loadable(() => import('../views/market-trends')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.OVERVIEW,
      component: Loadable(() => import('../views/market-trends/overview')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.CBS,
      permission: PERMISSIONS.MARKET_TRENDS.CBS,
      component: Loadable(() =>
        import('../views/market-trends/certification-bodies')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.CERTS,
      permission: PERMISSIONS.MARKET_TRENDS.CERTS,
      component: Loadable(() =>
        import('../views/market-trends/certifications')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.CES,
      permission: PERMISSIONS.MARKET_TRENDS.CES,
      component: Loadable(() =>
        import('../views/market-trends/certified-entities')
      ),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.LOCATIONS,
      permission: PERMISSIONS.MARKET_TRENDS.LOCATIONS,
      component: Loadable(() => import('../views/market-trends/locations')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.SECTORS,
      // permission: PERMISSIONS.MARKET_TRENDS.SECTORS,
      component: Loadable(() => import('../views/market-trends/sectors')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.PAGE_VIEWS,
      permission: PERMISSIONS.MARKET_TRENDS.PAGEVIEWS,
      component: Loadable(() => import('../views/market-trends/page-views')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.USERS,
      permission: PERMISSIONS.MARKET_TRENDS.USERS,
      component: Loadable(() => import('../views/market-trends/users')),
    },
  ],
};

export default marketTrends;
