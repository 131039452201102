import axios from 'axios';
import queryString from 'qs';
import cleanDeep from 'clean-deep';
import {isEmpty, isUndefined} from 'lodash/lang';
import Cookies from 'utils/cookies';
import {API_URL, NGROK_API_URL, ERROR_STATUS} from 'configs';
import ERROR_MESSAGE from 'configs/ErrorMessages';

//
// UKAS
axios.defaults.baseURL = NGROK_API_URL || API_URL;
axios.defaults.timeout = 50 * 1000; // 50 second timeout
axios.defaults.timeoutErrorMessage = ERROR_MESSAGE.timeout;

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    // Set bsid
    // Check bsid in 'defaults' first,
    // then reuse it even if cookie has been changed.
    const bsid = Cookies.bsid;
    if (!config.headers.common['x-http-bsid']) {
      axios.defaults.headers.common['x-http-bsid'] = bsid;
      config.headers.common['x-http-bsid'] = bsid;
    }

    // Set token
    // Check token in current cookie first, then
    // 'headers' will be overridden using current.
    const session = Cookies.session || '';
    if (!isEmpty(session)) {
      axios.defaults.headers.common['x-http-token'] = session;
      config.headers.common['x-http-token'] = session;
    } else {
      delete axios.defaults.headers.common['x-http-token'];
      delete config.headers.common['x-http-token'];
    }

    const captchaToken = Cookies.captchaToken || null;
    if (captchaToken) {
      axios.defaults.headers.common['x-http-captcha-token'] = captchaToken;
      config.headers.common['x-http-captcha-token'] = captchaToken;
    }

    if (NGROK_API_URL) {
      axios.defaults.headers.common['ngrok-skip-browser-warning'] = '1';
      config.headers.common['ngrok-skip-broswer-warning'] = '1';
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

async function success(response) {
  return Promise.resolve(response.data);
}

function failure(error) {
  if (error.message === ERROR_MESSAGE.timeout) {
    // if it hits 50 second (default timeout) or the desired timeout we are going to redirect to 504
    console.log(error.message);
    window.location.replace('/error/504');
    // This are to match the alertByError(e)
    return Promise.reject({
      errors: {code: 'timeout', message: ERROR_MESSAGE.timeout},
    });
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log('data', error.response.data);
    // console.log('status', error.response.status);
    // console.log('headers', error.response.headers);

    //
    // Session expired
    const status = ERROR_STATUS[error.response.status || '404'];
    const {code} = error.response.data.errors || {};

    // Session expired
    if (code === 'invalid_session_token') {
      Cookies.session = '';
      window.location.replace(`/error/session`);
      return;
    }

    // With defined error code
    if (
      //status === ERROR_STATUS['401'] ||
      status === ERROR_STATUS['404'] ||
      status === ERROR_STATUS['500']
    ) {
      // Redirect
      window.location.replace(`/error/${status}`);
      return;
    } else if (status === ERROR_STATUS['503']) {
      window.location.replace('/maintenance');
      return;
    }

    return Promise.reject(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('request', error.request);
    // window.location.replace('/error/504');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log('config', error.config);
}

export function get(url, params, config) {
  return axios
    .get(url, {
      params: makeSearchParams(cleanDeep(params)), // Clean param object
      ...config,
    })
    .then(success)
    .catch(failure);
}

export function post(url, data, config) {
  return axios.post(url, data, config).then(success).catch(failure);
}

export function put(url, data, config) {
  return axios.put(url, data, config).then(success).catch(failure);
}

export function remove(url, data, config) {
  return axios
    .delete(url, {data, ...config})
    .then(success)
    .catch(failure);
}

//
// Existing from IAF

export const queryParse = (string) => {
  return queryString.parse(string, {ignoreQueryPrefix: true});
};

export const queryStringify = (obj) => {
  return queryString.stringify(obj);
};

export const makeSearchParams = (queries = {}) => {
  if (isEmpty(queries)) return {};

  if (typeof queries === 'string') {
    queries = queryParse(queries);
  }

  const {limit, offset, order, order_by, filter, key, ...rest} = queries;

  let params = {
    limit: isUndefined(limit) ? 20 : limit,
    offset: isUndefined(offset) ? 0 : offset,
    key: isUndefined(key) ? '' : key,
  };

  if (limit < 0) {
    delete params.limit;
  }

  if (offset < 0) {
    delete params.offset;
  }

  if (!isUndefined(order)) params.order = order;
  if (!isUndefined(order_by)) params.order_by = order_by;

  params.filter = {
    ...filter,
    ...rest,
  };

  return cleanDeep(params);
};

const http = (method = 'GET', url, data, options) => {
  let config = {
    method: method,
    url: url,
    ...options,
  };

  if (method.toUpperCase() === 'GET') {
    config.params = data;
  } else {
    config.data = data;
  }

  return axios(config).then(success).catch(failure);
};

export default http;
