import {useEffect} from 'react';
import {postAccountOTPSubscribe} from 'api/accounts';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useGetOTPSubscribe() {
  const [fetch, isLoading, response] = useFetch(postAccountOTPSubscribe, {
    initialLoading: true,
  });
  const {alertByError} = useAlert();

  const getData = async () => {
    try {
      await fetch();
    } catch (e) {
      await alertByError(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const {data = {}} = response;
  return {isLoading, data};
}

export default useGetOTPSubscribe;
