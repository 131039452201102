import React from 'react';
import {Redirect} from 'react-router-dom';
import {ROUTE_PATH} from 'constants/routes';
// import Loadable from 'components/Loadable';

const about = {
  path: ROUTE_PATH.ABOUT_US,
  component: () => <Redirect to="/coming" />,
  // component: Loadable(() => import('../views/about')),
};

export default about;
