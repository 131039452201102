import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import defaultsPlugin from 'store/plugins/defaults';
import expirePlugin from 'store/plugins/expire';

import {COOKIE_PREFIX, DATA_VERSION} from 'configs/Environment';
import {isArray, isEmpty} from 'lodash/lang';

// Example custom build usage:
const storages = [localStorage];
const plugins = [defaultsPlugin, expirePlugin];

const store = engine.createStore(storages, plugins);

function generateName(name) {
  if (!name || typeof name !== 'string') return '';
  return [COOKIE_PREFIX, name].filter((v) => v).join('-');
}

function getStore(name) {
  const storeName = generateName(name);
  const obj = store.get(storeName);

  if (!isEmpty(obj) && isArray(obj)) return null;

  const {version, data} = obj || {};

  if (version === DATA_VERSION) {
    return data;
  } else {
    return null;
  }
}

function setStore(
  name,
  value,
  expiry = new Date().getTime() + 30 * 24 * 60 * 60 * 1000 // 30 days
) {
  const storeName = generateName(name);

  store.set(
    storeName,
    {
      version: DATA_VERSION,
      data: value,
    },
    expiry
  );
}

const Stores = {
  // Country list
  get countries() {
    return getStore('country');
  },
  set countries(value) {
    setStore(
      'country',
      value,
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000 /* 7 days */
    );
  },

  // Region list
  get regions() {
    return getStore('regions');
  },
  set regions(value) {
    setStore('regions', value);
  },

  // Browser locale
  get industries() {
    return getStore('industries');
  },
  set industries(value) {
    setStore('industries', value);
  },

  // Browser locale
  get locale() {
    return getStore('locale');
  },
  set locale(value) {
    setStore('locale', value);
  },

  // Captcha: g for reCaptcha, h for hCaptcha
  get captcha() {
    return getStore('captcha');
  },
  set captcha(value) {
    setStore('captcha', value);
  },

  // Alert
  get alert() {
    return getStore('alert');
  },

  set alert(value) {
    setStore('alert', value);
  },

  // Benchmark
  get benchmark() {
    return getStore('benchmark');
  },

  set benchmark(value) {
    setStore('benchmark', value);
  },
};

export default Stores;
