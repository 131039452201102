import qs from 'qs';
import {assign, keys} from 'lodash/object';
import {cloneDeepWith, isObject, isString} from 'lodash/lang';
import urlJoin from 'url-join';

export function queryStringify(obj, options) {
  return qs.stringify(obj, options);
}

export function queryParse(str, options = {ignoreQueryPrefix: true}) {
  return qs.parse(str, options);
}

/**
 *
 * @param path: string '/foo'
 * @param obj: object {pathA: '/bar', ...}
 * @returns {*}
 */

export function mergeNodePath(path, obj) {
  const nodes = assign({}, obj);

  return cloneDeepWith(nodes, (value) => {
    if (typeof value === 'string') {
      return urlJoin(path, value);
    }
  });
}

export function buildRouteNodes(routes) {
  let routeNodes = assign({}, routes); // clone
  const rootPath = routeNodes['INDEX'];

  keys(routeNodes).forEach((key) => {
    let nodeItem = routeNodes[key];

    if (isString(nodeItem)) {
      if (key !== 'INDEX') {
        if (nodeItem === '/') {
          nodeItem = rootPath;
        } else {
          nodeItem = urlJoin(rootPath, nodeItem);
        }
      }
    } else if (isObject(nodeItem)) {
      nodeItem = mergeNodePath(rootPath, buildRouteNodes(nodeItem));
    }

    routeNodes[key] = nodeItem;
  });

  return routeNodes;
}
