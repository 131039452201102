import React from 'react';
import {UKAS_TITLE} from 'constants/environment';
import {useAuth} from 'contexts/AuthContext';
import {FormGroup, Label, FormText, Row, Col, Badge} from 'reactstrap';
import Layouts from 'components/Layouts';
import {PublicContainer, PublicFormContainer} from 'components/Publics';
import {DashboardForm, DashboardButton} from 'components/Dashboard';
import SecurityAuthFields from 'views/settings/security/overview/SecurityAuthFields';
import useVerifyOTP from './useVerifyOTP';
import useConfirmVerification from './useConfirmVerification';

function Index() {
  const {updateAccount} = useAuth();
  const {isConfirming, confirmVerification} = useConfirmVerification(
    callbackUpdateAccount
  );
  const {isVerifying, verifyOTP} = useVerifyOTP(confirmVerification);

  function callbackUpdateAccount() {
    updateAccount({account: {account_otp_optout: 1}});
  }

  return (
    <Layouts header={false} footer={false}>
      <PublicContainer>
        <PublicFormContainer>
          <div className="mb-5 text-center">
            <h1 className="display-4 mb-4">Set up 2-step verification</h1>
            <p className="text-dark mb-0">Add more way to verify it's you</p>
          </div>

          <p className="mb-4">
            For your security, {UKAS_TITLE} wants to make sure that it's you who
            are signing in. A 2-step verification will be added whenever you
            sign in.
          </p>

          <FormGroup>
            <Row>
              <Col>
                <Label className="text-dark">Email Guard</Label>
              </Col>
              <Col className="col-auto">
                <Badge color={'soft-info'}>Enabled</Badge>
              </Col>
            </Row>
            <FormText>
              2-step verification code will be sent through email.
            </FormText>
          </FormGroup>

          <hr />

          <FormGroup>
            <Label className="text-dark">Google Authenticator</Label>
            <FormText>
              2-step verification code will be generated through app.
            </FormText>
          </FormGroup>

          <DashboardForm onSubmit={verifyOTP}>
            <fieldset disabled={isVerifying}>
              <SecurityAuthFields />

              <Row className="mt-4">
                <Col>
                  <DashboardButton
                    color="link"
                    className="text-primary"
                    isLoading={isConfirming}
                    onClick={confirmVerification}
                  >
                    Skip
                  </DashboardButton>
                </Col>
                <Col>
                  <DashboardButton
                    isLoading={isVerifying || isConfirming}
                    type="submit"
                  >
                    Enable authenticator
                  </DashboardButton>
                </Col>
              </Row>
            </fieldset>
          </DashboardForm>
        </PublicFormContainer>
      </PublicContainer>
    </Layouts>
  );
}

export default Index;
