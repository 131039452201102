import {Layouts} from 'components/Layouts';
import {PublicContainer, PublicFormContainer} from 'components/Publics';
import React from 'react';
import CompromisedForm from './CompromisedForm';

function Index() {
  return (
    <Layouts header={false} footer={false}>
      <PublicContainer>
        <PublicFormContainer>
          <CompromisedForm />
        </PublicFormContainer>
      </PublicContainer>
    </Layouts>
  );
}

export default Index;
