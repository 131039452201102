import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/en-au';
import {isEmpty} from 'lodash/lang';

// const language = window.navigator.userLanguage || window.navigator.language;
// moment.locale(language || 'en-GB');

/*function isIsoDate(str) {
  console.log(str, /\d{4}-\d{2}-\d{2}/.test(str));
  console.log(str, /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str));

  if (
    !/\d{4}-\d{2}-\d{2}/.test(str) &&
    !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)
  ) {
    return false;
  }

  console.log(new Date(str).toISOString());

  var d = new Date(str);
  return d.toISOString() === str;
}*/

/**
 * Change date format
 *
 * @param {string|timestamp} date
 * @param {string} format
 * @returns {string|null}
 */

export const dateToFormatted = (date, format = 'YYYY-MM-DD') => {
  if (!date) return null;
  return moment(date).format(format);
};

/**
 * Determine whether value is date
 *
 * @example
 *
 * dateToTimestamp('2012-12-12'); // true
 * dateToTimestamp(1606205966448); // false
 * dateToTimestamp('1606205966448'); // false
 * dateToTimestamp('12/12/2012'); // false
 * dateToTimestamp('2020-11-24T08:19:26.448Z'); // false
 *
 * @param {string} date
 * @returns {boolean}
 */
function isValidDate(date) {
  if (isEmpty(date)) return false;

  // @note
  // Safari doesn't accept 'yyyy-mm-dd'
  // it requires converting format like '2020-11-24T08:19:26.448Z'
  const t = moment(date).toISOString();
  const d = new Date(t);

  return d instanceof Date && !isNaN(d);
}

/**
 * Determine whether value is timestamp
 *
 * @example
 *
 * dateToTimestamp(1606205966448); // true
 * dateToTimestamp('1606205966448'); // true
 * dateToTimestamp('2012-12-12'); // false
 * dateToTimestamp('12/12/2012'); // false
 * dateToTimestamp('2020-11-24T08:19:26.448Z'); // false
 *
 * @param {string|timestamp} timestamp
 * @returns {boolean}
 */
function isValidTimestamp(timestamp) {
  if (!timestamp) return false;

  const parsed = parseFloat(timestamp);

  return (
    !Number.isNaN(parsed) &&
    Number.isFinite(parsed) &&
    /^\d+\.?\d+$/.test(timestamp)
  );
}

export function dateDiff(dateEarlier, dateLater) {
  const one_day = 60 * 60 * 24;
  return Math.round((dateLater - dateEarlier) / one_day);
}

export function getDateAfterAmount(date, amount = 1, unit = 'days') {
  if (!date) return '';
  return moment(date).add(amount, unit).format('YYYY-MM-DD');
}

export function getDateBeforeAmount(date, amount = 1, unit = 'days') {
  if (!date) return '';
  return moment(date).subtract(amount, unit).format('YYYY-MM-DD');
}

export const getTimestamp = (addDate = 0) => {
  // Our system uses timeUnix Timestamp (milliseconds)
  // if Unix Timestamp (seconds): moment().unix();
  return moment().unix() + addDate * 24 * 60 * 60;

  // const date = new Date();
  // return date.getTime() + addDate * 24 * 60 * 60 * 1000;
};

/**
 * Date to locale date
 *
 * @example
 *
 * dateToTimestamp('2012-12-12'); // true
 * dateToTimestamp(1606205966448); // false
 * dateToTimestamp('1606205966448'); // false
 * dateToTimestamp('12/12/2012); // false
 * dateToTimestamp('2020-11-24T08:19:26.448Z'); // false
 *
 * @param {string} date
 * @param {boolean} showTime
 * @returns {string|null}
 */

export const dateToLocale = (date, showTime = false) => {
  if (!isValidDate(date)) return null;

  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  if (showTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }

  // @note
  // Safari doesn't accept 'yyyy-mm-dd'
  // it requires converting format like '2020-11-24T08:19:26.448Z'
  const t = moment(date).toISOString();
  const d = new Date(t);
  return new Intl.DateTimeFormat('default', options).format(d);
};

/**
 * Timeframe to locale date
 *
 * @example
 *
 * dateToTimestamp(1606205966448); // true
 * dateToTimestamp('1606205966448'); // true
 * dateToTimestamp('2012-12-12'); // false
 * dateToTimestamp('12/12/2012); // false
 * dateToTimestamp('2020-11-24T08:19:26.448Z'); // false
 *
 * @param {string|timestamp} timestamp
 * @param {boolean} showTime
 * @returns {string|null}
 */

export const timestampToLocale = (
  timestamp,
  showTime = false,
  showSeconds = true,
  format = 'en-US'
) => {
  if (!isValidTimestamp(timestamp)) return null;

  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  if (showTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    if (showSeconds) options.second = 'numeric';
  }

  const t = moment(timestamp * 1000).toISOString();
  const d = new Date(t);

  return new Intl.DateTimeFormat(format, options).format(d);
  //return moment(timestamp * 1000).format(showTime ? 'L hh:mm' : 'L');
};

/**
 * Timestamp to from now
 *
 * @example
 *
 * dateToTimestamp(1606205966448); // true
 * dateToTimestamp('1606205966448'); // true
 * dateToTimestamp('2012-12-12'); // false
 * dateToTimestamp('12/12/2012); // false
 * dateToTimestamp('2020-11-24T08:19:26.448Z'); // false
 *
 * @param timestamp
 * @returns {string|null}
 */
export const timestampToFromNow = (timestamp) => {
  if (!isValidTimestamp(timestamp)) return null;

  return moment(timestamp * 1000).fromNow();
};

/**
 * Date to timestamp
 * The date must be ISO8601 format
 *
 * @example
 *
 * dateToTimestamp('2012-12-12'); // true
 * dateToTimestamp(1606205966448); // false
 * dateToTimestamp('1606205966448'); // false
 * dateToTimestamp('12/12/2012); // false
 * dateToTimestamp('2020-11-24T08:19:26.448Z'); // false
 *
 * @param {string} date
 * @returns {number}
 */
export const dateToTimestamp = (date) => {
  const d = new Date(date);
  return moment(d).unix();
};
