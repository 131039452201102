import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';

const companyProfile = {
  path: ROUTE_PATH.DASHBOARD.COMPANY_PROFILE.INDEX,
  // permission: 'company_profile.access',
  component: Loadable(() => import('../views/company-profile')),
};

export default companyProfile;
