import styles from './Security.module.scss';
import React from 'react';
import QRCode from 'qrcode.react';
import {Label, Input} from 'reactstrap';
import {DashboardFormField} from 'components/Dashboard';
import {SpinnerLoading} from 'components/Loadings';
import useGetOTPSubscribe from './useGetOTPSubscribe';
import {UKAS_TITLE} from 'constants/environment';

SecurityAuthFields.propTypes = {};

function SecurityAuthFields() {
  const {isLoading, data} = useGetOTPSubscribe();
  const {company, email, key} = data;

  const encoded = encodeURI(
    `${company}:${email}?secret=${key}&issuer=${company}`
  );
  const valueQRCode = `otpauth://totp/${encoded}`;

  if (isLoading)
    return (
      <div className="py-5">
        <SpinnerLoading isLoading />
      </div>
    );

  return (
    <fieldset disabled={isLoading}>
      <div style={{textAlign: 'center'}}>
        <div className="my-3">
          <QRCode value={valueQRCode} />
        </div>

        <Label className="text-dark">Authenticator secret code</Label>

        <p>
          <code style={{backgroundColor: '#F6F6F6'}}>{key}</code>
        </p>
      </div>

      <hr />

      <ol className={styles['security__description-list']}>
        <li>
          Install an authenticator app on your mobile device if you don't
          already have one.
        </li>
        <li>
          Scan QR code with the authenticator (or tap it in mobile browser)
        </li>
        <li>
          Please write down or print a copy of the 16-digit secret code and put
          it in a safe place.
        </li>
        <li>
          If your phone gets lost, stolen or erased, you will need this code to
          link {UKAS_TITLE} to a new authenticator app install once again.
        </li>
        <li>
          Do not share it with anyone. Be aware of phishing scams. We will never
          ask you for this key.
        </li>
      </ol>

      <div style={{backgroundColor: '#F6F6F6', padding: '1rem'}}>
        <p>
          Enter the Two-Factor authentication code provided by your
          authentication app.
        </p>

        <DashboardFormField row label="2FA Code" className="mb-0">
          <Input type="text" name="secret" placeholder="2FA Code" required />
        </DashboardFormField>
      </div>
    </fieldset>
  );
}

export default SecurityAuthFields;
