import React from 'react';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import {get} from 'lodash/object';
import {dateDiff} from 'utils/dates';
import {PASSWORD_EXPIRY_DAYS} from 'constants/environment';

import {ROUTE_ACCESS_RULES} from 'configs';
import {ROUTE_PATH} from 'constants/routes';
import {useAuth} from 'contexts/AuthContext';
import {useMaintenance} from 'contexts/MaintenanceContext';
import VerificationEmail from 'views/verification/email/index';
import VerificationTwoFactor from 'views/verification/two-factor/index';
import PasswordExpiry from 'views/password/expiry/index';
import PasswordCompromised from 'views/password/compromised/index';

const MAINTENANCE_EXCEPTIONS = [
  '/maintenance',
  '/error',
  '/login',
  '/certification',
  '/certification-body',
  '/consents',
  '/contact',
  '/e',
  '/logout',
  '/password',
  '/policies',
  '/search',
  '/enquiry',
  '/about',
  '/',
];

const RouteComponent = (props) => {
  const {access, routes, component: Component, permission, ...rest} = props;
  const {isAuth, account: authAccount, permission: authPermission} = useAuth();
  const {isMaintenance} = useMaintenance();

  const isLocationLogout = useRouteMatch(ROUTE_PATH.LOG_OUT);

  //
  // Guest routes like Log in, Sign up, etc
  if (access === ROUTE_ACCESS_RULES.guest && isAuth) {
    return <Redirect to={ROUTE_PATH.DASHBOARD.OVERVIEW} />;
  }

  //
  // Maintenance
  const isNotMaintenanceExceptions = MAINTENANCE_EXCEPTIONS.every((p) => {
    return props.match.url !== p;
  });

  if (isMaintenance && isNotMaintenanceExceptions) {
    return <Redirect to={ROUTE_PATH.MAINTENANCE} />;
  }

  //
  // Private routes
  if (access === ROUTE_ACCESS_RULES.private) {
    // If isAuth is true, show verifications
    if (isAuth) {
      // Except logout page
      if (!isLocationLogout) {
        // Required to check consent
        // Required to check permission

        const {
          account_confirm_email,
          account_otp_optout,
          account_password_updated_at,
          account_password_breached,
        } = authAccount;

        // Needs to confirm email address
        if (account_confirm_email !== 1) {
          return <VerificationEmail />;
        }

        // Needs 2FA Verification
        if (account_otp_optout !== 1) {
          return <VerificationTwoFactor />;
        }

        if (account_password_breached) {
          return <PasswordCompromised />;
        }

        // Needs to update Expired password
        const today = new Date().getTime() / 1000;
        const diff = dateDiff(account_password_updated_at, today);
        if (diff > PASSWORD_EXPIRY_DAYS) {
          return <PasswordExpiry />;
        }
      }
    } else {
      // If isAuth is false, redirect to home
      return <Redirect to={ROUTE_PATH.HOME} />;
    }
  }

  //
  // User Permission
  if (permission) {
    const hasPermit = get(authPermission, permission);
    // if undefined its okay not to redirect but false should be main checker if it has no permission
    // sample of this is settings it should return undefined on AB but on CB it depends on the permission
    if (hasPermit === false) {
      return <Redirect to={ROUTE_PATH.DASHBOARD.OVERVIEW} />;
    }
  }

  //
  // Render
  return (
    <Component
      routes={routes}
      permission={get(authPermission, permission)}
      {...rest}
    />
  );
};

function renderRoutes(routes) {
  return (
    <Switch>
      {routes.map((route, index) => {
        const {access, routes, component, path, permission, ...rest} = route;

        return (
          <Route
            key={index}
            path={path}
            {...rest}
            render={(routeProps) => {
              return (
                <RouteComponent
                  access={access}
                  routes={routes}
                  component={component}
                  permission={permission}
                  {...routeProps}
                />
              );
            }}
          />
        );
      })}

      <Route exact path="*" render={() => <Redirect to="/error/404" />} />
    </Switch>
  );
}

export default renderRoutes;
