import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';

const cbSearch = {
  exact: true,
  path: ROUTE_PATH.CB_SEARCH,
  component: Loadable(() => import('../views/cb-search')),
};

export default cbSearch;
