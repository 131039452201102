import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const scopes = {
  path: ROUTE_PATH.DASHBOARD.SCOPES.INDEX,
  permission: PERMISSIONS.SCOPES.ACCESS,
  component: Loadable(() => import('../views/scopes')),
  routes: [
    // List
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SCOPES.LIST,
      component: Loadable(() => import('../views/scopes/list')),
    },

    // Technical Sectors
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SCOPES.SECTORS,
      permission: PERMISSIONS.SCOPES.TECHNICAL_SECTORS.VIEW,
      component: Loadable(() => import('../views/scopes/sectors')),
    },

    // Countries
    /*
    // Removed, 16 Sep 2021
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SCOPES.COUNTRIES,
      component: Loadable(() => import('../views/scopes/countries')),
    },*/

    // Countries
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SCOPES.DIRECTORIES,
      permission: PERMISSIONS.SCOPES.OFFICE_DIRECTORIES,
      component: Loadable(() => import('../views/scopes/office-directories')),
    },

    // Analytics
    // Removed as of 10/1/2021
    // {
    //   exact: true,
    //   path: ROUTE_PATH.DASHBOARD.SCOPES.ANALYTICS,
    //   component: Loadable(() => import('../views/scopes/analytics')),
    // },
  ],
};

export default scopes;
