import React from 'react';
import {useAuth} from 'contexts/AuthContext';
import Layouts from 'components/Layouts';
import {PublicContainer, PublicFormContainer} from 'components/Publics';
import {DashboardButton} from 'components/Dashboard';
import useChangePassword from 'views/settings/security/useChangePassword';
import useSkipPassword from './useSkipPassword';
import ExpiryForm from './ExpiryForm';

function Index() {
  const {updateAccount} = useAuth();
  const {isChangingPassword, changePassword} =
    useChangePassword(callbackSuccess);
  const {isSkipping, skipPassword} = useSkipPassword(callbackSuccess);

  function callbackSuccess(data) {
    const {account_password_updated_at} = data;

    updateAccount({
      account: {account_password_updated_at},
    });
  }

  return (
    <Layouts header={false} footer={false}>
      <PublicContainer>
        <PublicFormContainer>
          <div className="mb-5 text-center">
            <h1 className="display-4 mb-4">Password has expired</h1>
            <p className="mb-0">
              To continue using your account, we recommend you update your
              password.
            </p>
          </div>

          <ExpiryForm
            isSubmitting={isChangingPassword}
            onSubmit={changePassword}
          />

          <div className="text-center">
            <DashboardButton
              isLoading={isSkipping}
              color="ghost-secondary"
              onClick={skipPassword}
            >
              Skip
            </DashboardButton>
          </div>
        </PublicFormContainer>
      </PublicContainer>
    </Layouts>
  );
}

export default Index;
