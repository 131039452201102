import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
// import {PERMISSIONS} from 'constants/permissions';

const support = {
  path: ROUTE_PATH.DASHBOARD.SUPPORT,
  component: Loadable(() => import('../views/support')),
};

export default support;
