import {updateAccount} from 'api/accounts';

import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useConfirmVerification(callback) {
  const [fetch, isConfirming] = useFetch(updateAccount, {
    initialLoading: false,
  });
  const {alertByError} = useAlert();

  const confirmVerification = async () => {
    try {
      const {data} = await fetch({
        account_otp_optout: 1,
      });

      if (callback) callback(data);
    } catch (e) {
      await alertByError(e);
    }
  };

  return {isConfirming, confirmVerification};
}

export default useConfirmVerification;
