import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';
import {Redirect} from 'react-router';

const enquiries = {
  permission: PERMISSIONS.ENQUIRIES.ACCESS,
  path: ROUTE_PATH.DASHBOARD.ENQUIRIES.INDEX,
  component: Loadable(() => import('../views/enquiries')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.INDEX,
      component: () => <Redirect to="/enquiries/open" />,
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.OPEN,
      component: Loadable(() => import('../views/enquiries/open')),
    },

    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.RESOLVED,
      component: Loadable(() => import('../views/enquiries/resolved')),
    },

    // -  TBD
    // {
    //   exact: true,
    //   path: ROUTE_PATH.DASHBOARD.ENQUIRIES.ARCHIVED,
    //   component: Loadable(() => import('../views/enquiries/archived')),
    // },

    // @todo: needs to wrap by '/views' maybe?
    {
      path: ROUTE_PATH.DASHBOARD.ENQUIRIES.VIEWS.VIEW,
      permission: PERMISSIONS.ENQUIRIES.ACCESS,
      component: Loadable(() => import('../views/enquiries/view')),
    },
  ],
};

export default enquiries;
