import React, {useState} from 'react';
import {Icon} from 'components/Icons';
import {FieldPassword} from 'components/Fields';
import {DashboardButton} from 'components/Dashboard';
import {Form, FormFeedback, FormGroup, Label} from 'reactstrap';
import {formSubmit} from 'utils/forms';
import useChangePassword from 'views/settings/security/useChangePassword';
import {Modal} from 'components/Modals';
import {REGEX_FEEDBACK} from 'constants/regex';
import {InputPassword} from 'components/Inputs';
import {useAuth} from 'contexts/AuthContext';

function CompromisedForm() {
  const {updateAccount} = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [inBreached, setInBreached] = useState(true);
  const [data, setData] = useState();
  const {isChangingPassword, changePassword} = useChangePassword(callback);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function callback(data) {
    toggle();
    setData(data);
  }

  function callbackSuccess() {
    setIsOpen(!isOpen);
    const {account_password_breached} = data;

    updateAccount({
      account: {account_password_breached},
    });
  }

  return (
    <>
      <Form onSubmit={formSubmit(changePassword, {empty: false})} noValidate>
        <div className="text-center">
          <Icon
            type="tio"
            icon="security-warning"
            className="text-warning mr-1"
            size="xl"
          />
          <span className="display-4 text-danger">Compromised Password</span>

          <p>
            The given password has appeared in a data leak, which puts this
            account at high risk of compromise. You should change your password
            immediately.
          </p>
        </div>

        <FormGroup>
          <Label>Old Password</Label>

          <InputPassword name="old_password" pattern={null} required />
          <FormFeedback>{REGEX_FEEDBACK.required}</FormFeedback>
        </FormGroup>

        <FieldPassword
          passwordLabel="New Password"
          passwordName="account_password"
          confirmPasswordLabel="Confirm New Password"
          confirmPasswordName="account_password_confirmation"
          inBreached={inBreached}
          setInBreached={setInBreached}
        />

        <DashboardButton
          type="submit"
          color="primary"
          block
          isLoading={isChangingPassword}
          disabled={inBreached}
        >
          Change password
        </DashboardButton>
      </Form>
      <div>
        <Modal
          isOpen={isOpen}
          okText="Okay"
          onOk={callbackSuccess}
          cancelButton={false}
          okButtonProps={{color: 'light'}}
          centered
        >
          <div className="text-center">
            <Icon
              type="fa"
              icon="check-circle"
              className="text-success mb-3 display-3"
            />
            <p className="display-4 mb-1">Password has been updated</p>
            {/* <span>You may now login using your new password.</span> */}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default CompromisedForm;
