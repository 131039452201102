import React from 'react';
import {Redirect} from 'react-router-dom';
// import Loadable from 'components/Loadable';

const consent = {
  exact: true,
  path: '/consents',
  component: () => <Redirect to="/coming" />,
  // component: Loadable(() => import('../views/consents')),
};

export default consent;
