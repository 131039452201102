import Loadable from 'components/Loadable';
// import {Redirect} from 'react-router-dom';

const register = {
  path: '/register',
  component: Loadable(() => import('../views/register')),
  routes: [
    {
      exact: true,
      path: '/register',
      component: Loadable(() => import('../views/register/type')),
    },
    {
      exact: true,
      path: '/register/user',
      component: Loadable(() => import('../views/register/user')),
    },
  ],
};

export default register;
