import React, {useState} from 'react';
import cx from 'classnames';
import ReactCodeInput from 'react-verification-input';
import {formSubmit} from 'utils/forms';
import {Form, FormGroup, Button, Spinner} from 'reactstrap';
import Layouts from 'components/Layouts';
import {PublicContainer, PublicFormContainer} from 'components/Publics';
import {Icon} from 'components/Icons';
import useVerification from './useVerification';
import useResendEmail from './useResendEmail';

function Index() {
  const [code, setCode] = useState(null);
  const [resent, setResent] = useState(false);

  const {isLoading, verify} = useVerification();
  const {isLoading: isSending, resend} = useResendEmail(() => setResent(true));

  async function handleSubmit() {
    const formData = {
      eg_code: code,
    };

    await verify(formData);
  }

  return (
    <Layouts header={false} footer={false}>
      <PublicContainer>
        <PublicFormContainer>
          <div className="mb-5 text-center">
            <div className="mb-4">
              <Icon
                type="fa"
                className="text-primary"
                icon="envelope-open-text"
                style={{fontSize: '5rem'}}
              />
            </div>

            <h1 className="display-4 mb-4">Account has been created</h1>
            <p className="mb-0">
              We've sent on activation code to your email address.
              <br />
              Please enter the code below.
            </p>
          </div>

          <Form onSubmit={formSubmit(handleSubmit, {empty: false})} noValidate>
            <fieldset disabled={isLoading}>
              <FormGroup className="text-center mb-5">
                <ReactCodeInput
                  name="code"
                  inputMode="text"
                  type="text"
                  fields={6}
                  onChange={setCode}
                  placeholder=""
                  removeDefaultStyles
                  classNames={{
                    container: 'react-verification-input__container',
                    character: 'react-verification-input__character',
                    characterInactive:
                      'react-verification-input__character--inactive',
                    characterSelected:
                      'react-verification-input__character--selected',
                  }}
                />
              </FormGroup>

              <Button type="submit" color="primary" block disabled={isLoading}>
                {isLoading ? <Spinner size="sm" /> : 'Submit'}
              </Button>
            </fieldset>
          </Form>

          <div className="mt-5 text-center">
            Don't receive the code?
            <span
              className={cx('ml-2 btn-link font-weight-normal', {
                disabled: isSending || resent,
              })}
              onClick={resend}
              role="button"
            >
              {isSending ? 'Resending...' : resent ? 'Sent' : 'Resend'}
            </span>
          </div>
        </PublicFormContainer>
      </PublicContainer>
    </Layouts>
  );
}

export default Index;
