import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const certifiedEntities = {
  permission: PERMISSIONS.CES.ACCESS,
  path: ROUTE_PATH.DASHBOARD.CES.INDEX,
  component: Loadable(() => import('../views/certified-entities')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CES.LIST,
      component: Loadable(() => import('../views/certified-entities/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CES.CREATE,
      permission: PERMISSIONS.CES.OVERVIEW.CREATE,
      component: Loadable(() => import('../views/certified-entities/create')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.CES.VIEWS.INDEX,
      component: Loadable(() => import('../views/certified-entities/view')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.VIEW,
          component: Loadable(() =>
            import('../views/certified-entities/view/details')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.EDIT,
          permission: PERMISSIONS.CES.VIEWS.EDIT,
          component: Loadable(() =>
            import('../views/certified-entities/view/edit')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.LIST,
          permission: PERMISSIONS.CERTS.ACCESS,
          component: Loadable(() =>
            import('../views/certified-entities/view/certifications/list')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.CERTS.CREATE,
          permission: PERMISSIONS.CES.VIEWS.CERTS.ADD,
          component: Loadable(() =>
            import('../views/certified-entities/view/certifications/create')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.ACTIVITY,
          component: Loadable(() =>
            import('../views/certified-entities/view/activity')
          ),
        },
        {
          path: ROUTE_PATH.DASHBOARD.CES.VIEWS.UPDATE_HISTORY,
          component: Loadable(() =>
            import('../views/certified-entities/view/update-history')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.UPDATE_HISTORY.LIST,
              component: Loadable(() =>
                import('../views/certified-entities/view/update-history/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CES.VIEWS.UPDATE_HISTORY
                .COMPARE_VERSIONS,
              component: Loadable(() =>
                import(
                  '../views/certified-entities/view/update-history/compare-versions'
                )
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default certifiedEntities;
