import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const certifications = {
  path: ROUTE_PATH.DASHBOARD.CERTS.INDEX,
  permission: PERMISSIONS.CERTS.ACCESS,
  component: Loadable(() => import('../views/certifications')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CERTS.LIST,
      component: Loadable(() => import('../views/certifications/list')),
    },
    // @todo: need to remove create
    // {
    //   exact: true,
    //   path: ROUTE_PATH.DASHBOARD.CERTS.CREATE,
    //   component: Loadable(() => import('../views/certifications/create')),
    // },
    {
      path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.INDEX,
      component: Loadable(() => import('../views/certifications/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.VIEW,
          component: Loadable(() =>
            import('../views/certifications/views/view')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.EDIT,
          permission: PERMISSIONS.CERTS.VIEWS.EDIT,
          component: Loadable(() =>
            import('../views/certifications/views/edit')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.SITES,
          permission: PERMISSIONS.CERTS.VIEWS.SITES.VIEW,
          component: Loadable(() =>
            import('../views/certifications/views/sites')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.TECHNICAL_SECTORS,
          permission: PERMISSIONS.CERTS.VIEWS.TECHNICAL_SECTORS.VIEW,
          component: Loadable(() =>
            import('../views/certifications/views/technical-sectors')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.RENEWAL_HISTORY,
          permission: PERMISSIONS.CERTS.VIEWS.RENEWAL_HISTORY.VIEW,
          component: Loadable(() =>
            import('../views/certifications/views/renewal-history')
          ),
        },
        // {
        //   exact: true,
        //   path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.UPDATE_HISTORY,
        //   permission: PERMISSIONS.CERTS.VIEWS.UPDATE_HISTORY,
        //   component: Loadable(() =>
        //     import('../views/certifications/views/update-history')
        //   ),
        // },
        {
          path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.UPDATE_HISTORY.INDEX,
          permission: PERMISSIONS.CERTS.VIEWS.UPDATE_HISTORY,
          component: Loadable(() =>
            import('../views/certifications/views/update-history')
          ),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.UPDATE_HISTORY.LIST,
              component: Loadable(() =>
                import('../views/certifications/views/update-history/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CERTS.VIEWS.UPDATE_HISTORY
                .COMPARE_VERSIONS,
              component: Loadable(() =>
                import(
                  '../views/certifications/views/update-history/compare-versions'
                )
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default certifications;
