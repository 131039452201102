import React from 'react';
import {Redirect} from 'react-router-dom';

import {ROUTE_ACCESS_RULES} from 'configs';
import Loadable from 'components/Loadable';

// Private (Dashboard)
import branches from './branches';
import cbProfile from './cb-profile';
import companyProfile from './company-profile';
import certificationBodies from './certification-bodies';
import certifications from './certifications';
import certifiedEntities from './certified-entities';
import dashboard from './dashboard';
import dataManagement from './data-management';
import enquiries from './enquiries';
import libraries from './libraries';
import scopes from './scopes';
import users from './users';
import marketTrends from './market-trends';
import settings from './settings';
import watchList from './watch-list';
import searchHistory from './search-history';
import support from './support';
import assets from './assets';

// Public
import about from './about';
import activate from './activate';
import certification from './certification';
import certifiedEntity from './certified-entity';
import certificationBody from './certification-body';
import consents from './consents';
import contact from './contact';
import coming from './coming';
import demo from './demo';
import elinks from './elinks';
import landing from './landing';
import login from './login';
import logout from './logout';
import password from './password';
import policies from './policies';
import register from './register';
import search from './search';
import certNotFound from './cert-not-found';
import cbSearch from './cb-search';

/*
Example:
{
env: ROUTE_ENV_RULES.development,
access: ROUTE_ACCESS_RULES.public,
...certification,
},
*/

const routes = [
  {
    access: ROUTE_ACCESS_RULES.public,
    ...about,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...activate,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...branches,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...cbProfile,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...companyProfile,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...certification,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...certificationBodies,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certificationBody,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...certifications,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...certifiedEntities,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certifiedEntity,
  },

  {
    access: ROUTE_ACCESS_RULES.private,
    ...consents,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...contact,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...coming,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...demo,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...dashboard,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...dataManagement,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...elinks,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...enquiries,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...landing,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...login,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...logout,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...password,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...policies,
  },

  {
    access: ROUTE_ACCESS_RULES.guest,
    ...register,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...search,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...settings,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...libraries,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...scopes,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...users,
  },

  {
    access: ROUTE_ACCESS_RULES.private,
    ...marketTrends,
  },

  {
    access: ROUTE_ACCESS_RULES.private,
    ...watchList,
  },

  {
    access: ROUTE_ACCESS_RULES.private,
    ...searchHistory,
  },

  {
    access: ROUTE_ACCESS_RULES.private,
    ...support,
  },

  {
    access: ROUTE_ACCESS_RULES.private,
    ...assets,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...certNotFound,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...cbSearch,
  },

  {
    exact: true,
    path: '/error/:code',
    component: Loadable(() => import('../views/errors')),
  },
  {
    exact: true,
    path: '/maintenance',
    component: Loadable(() => import('../views/maintenance')),
  },
  {
    exact: true,
    path: '/not-found',
    component: Loadable(() => import('../views/errors')),
  },
  {
    component: () => <Redirect to="/not-found" />,
  },
];

export default routes;
