import styles from './LandingSearchSelect.module.scss';
import React, {useState, useContext} from 'react';

import {Link, useHistory} from 'react-router-dom';
import {debounce} from 'lodash/function';
import axios from 'axios';
import Cookies from 'utils/cookies';
import useAlert from 'hooks/useAlert';

import {components} from 'react-select';
import {addSearchAccountHistory} from 'api/search';
import {AuthContext} from 'contexts/AuthContext';
import {Select} from 'components/Selects';
import cx from 'classnames';

LandingSearchSelectCert.defaultProps = {
  name: 'certification',
  isClearable: true,
  defaultOptions: false,
  verifyCaptcha: () => {},
  size: 'md',
};

function LandingSearchSelectCert(props) {
  const {isAuth} = useContext(AuthContext);
  const {alertByError} = useAlert();
  const history = useHistory();

  const {
    verifyCaptcha,
    handleTooManyRequest,
    name,
    selected,
    value,
    disabled,
    size,
    ...rest
  } = props;
  const [keyword, setKeyword] = useState('');
  const {Option} = components;

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];

    if (value) {
      await axios
        .get(`search/suggest-certificate`, {params: {q: value}})
        .then((response) => {
          const {data} = response.data;

          result = data.map((item) => {
            let obj = {};
            for (const [key, value] of Object.entries(item)) {
              obj = {
                company_certifications_id: key,
                certification_number: value,
              };
            }
            return obj;
          });

          return callback(result);
        })
        .catch((e) => {
          if (e.response.status === 429) {
            Cookies.captchaToken = '';
            alertByError(e.response.data);
            if (handleTooManyRequest) handleTooManyRequest();
          } else if (e.response.data.errors.code === 'invalid_captcha_token') {
            verifyCaptcha();
          } else {
            alertByError(e.response.data);
          }
        });
    }
  }

  const noOptionsMessage = ({inputValue}) =>
    inputValue ? (
      <div className="font-weight-bold text-dark">
        <div className="mb-2">
          Can’t find the certification you’re looking for?
        </div>
        <div>
          Click <Link to="/cert-not-found">here</Link> for further support.
        </div>
      </div>
    ) : (
      'Search companies by certificate number...'
    );

  function handleChange(selected) {
    if (selected) {
      if (isAuth) {
        addSearchAccountHistory({
          company_certifications_number:
            selected.certification_number.split(' | ')[1], // for company name on search history
          company_certifications_id: selected.company_certifications_id,
          keyword: keyword,
          type: 'certification',
        });
      }
      history.push(`/certification/${selected.company_certifications_id}`);
    }
  }

  const SuggestWithFlag = ({children, ...innerProps}) => {
    const [certNumber, companyName, companySubName] = children.split(' | ');

    return (
      <Option className="py-2 px-3 d-flex align-items-center" {...innerProps}>
        {/* <ImageFlag className="mr-2" code={countryCode} /> */}

        <span className="mr-1 text-dark" style={{fontSize: '1rem'}}>
          {certNumber} : <span className="text-body">{companyName}</span>
        </span>

        {companySubName && (
          <span className="text-muted" style={{fontSize: '0.875rem'}}>
            ({companySubName})
          </span>
        )}
      </Option>
    );
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      zIndex: 1,
      width: '100%',
      margin: '0 12px',
    }),
    input: (provided) => ({
      ...provided,
      zIndex: 2,
      width: '100%',
      margin: '0 12px',
    }),
  };

  const selectOptions = {
    className: cx(styles['landing-search__select'], {
      [styles['landing-search__select--lg']]: size === 'lg',
    }),
    loadOptions: debounce(loadOptions, 500),
    onChange: handleChange,
    getOptionLabel: (option) => option.certification_number,
    getOptionValue: (option) => option.company_certifications_id,
    noOptionsMessage: noOptionsMessage,
    disabled: disabled,
    async: true,
    isLoading: false,
    placeholder: 'Search by certificate number',
    name: 'certification',
    styles: customStyles,
    components: {
      Option: SuggestWithFlag,
      IndicatorSeparator: null,
      DropdownIndicator: null,
    },
    ...rest,
  };

  return <Select {...selectOptions} />;
}

export default LandingSearchSelectCert;
