import serialize from 'form-serialize';
import {merge} from 'lodash/object';
import {isEmpty} from 'lodash/lang';

export function formSerialize(form, options) {
  const {hash, empty} = options || {};

  const serializeOptions = {hash, empty};
  const defaultOptions = {hash: true, empty: true};

  return serialize(form, merge(defaultOptions, serializeOptions));
}

export function formValidate(form) {
  // react-select validation
  const selects = form.getElementsByClassName('react-select--invalid');

  if (form.checkValidity() === false || !isEmpty(selects)) {
    form.classList.add('was-validated');
    return false;
  }

  return true;
}

export function formSubmit(submit, options) {
  const {hash, empty} = options || {};

  return function (e) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.target;
    const isValid = formValidate(form);

    if (isValid) {
      const formData = formSerialize(form, {hash, empty});
      submit(formData);
    }
  };
}
