import Cookie from 'universal-cookie';
import {encrypt, decrypt} from 'utils/encrypts';
import {isEmpty} from 'lodash/lang';
import {
  APP_STAGE,
  IS_ENV_DEV,
  COOKIE_KEY,
  COOKIE_PREFIX,
  COOKIE_DOMAIN,
} from 'constants/environment';

// Expiry dates
const SESSION_EXPIRES = 7;
const DATA_EXPIRES = 365;

// Create a Cookie
const cookie = new Cookie();

function dayToSecond(day) {
  return day * 24 * 60 * 60;
}

function createCookieName(name) {
  if (!name || typeof name !== 'string') return null;
  return [COOKIE_PREFIX, name].filter((v) => v).join('-');
}

function getCookie(name, options) {
  const cookieName = createCookieName(name);
  return cookie.get(cookieName, options);
}

function setCookie(name, value, options) {
  const cookieName = createCookieName(name);
  const cookieOptions = {
    path: '/',
    domain: IS_ENV_DEV ? '' : COOKIE_DOMAIN, // domain for the cookie (sub.domain.com or .allsubdomains.com)
    secure: APP_STAGE === 'production',
    sameSite: APP_STAGE === 'production' ? 'none' : '',
    ...options,
  };

  if (isEmpty(value)) {
    cookie.remove(cookieName, cookieOptions);
  } else {
    cookie.set(cookieName, value, cookieOptions);
  }
}

function getEncryptCookie(name, options) {
  const cookieValue = getCookie(name, options);
  return isEmpty(cookieValue) ? null : decrypt(cookieValue, COOKIE_KEY);
}

function setEncryptCookie(name, value, options) {
  const cookieValue = isEmpty(value)
    ? null
    : encrypt(value, COOKIE_KEY).toString();
  setCookie(name, cookieValue, options);
}

const Cookies = {
  // Auth Session
  get session() {
    return getEncryptCookie('session');
  },
  set session(objValue) {
    const {remember, session} = objValue;
    const options = remember
      ? {maxAge: dayToSecond(SESSION_EXPIRES)}
      : undefined;

    setEncryptCookie('session', session, options);
  },

  // Browser ID (fingerprint)
  get bsid() {
    return getEncryptCookie('bsid');
  },
  set bsid(value) {
    const options = {maxAge: dayToSecond(DATA_EXPIRES)};
    setEncryptCookie('bsid', value, options);
  },

  // Captcha token
  get captchaToken() {
    return getEncryptCookie('captcode');
  },
  set captchaToken(value) {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const options = {expires: tomorrow};
    setEncryptCookie('captcode', value, options);
  },

  // Consent version
  get consent() {
    return getEncryptCookie('consent');
  },
  set consent(value) {
    const options = {expires: 365};
    setEncryptCookie('consent', value, options);
  },

  // Country code
  get countryCode() {
    return getEncryptCookie('countryCode');
  },
  set countryCode(value) {
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    const options = {expires: aYearFromNow};
    setEncryptCookie('countryCode', value, options);
  },

  // Email Session for Log in
  get emailSession() {
    return getEncryptCookie('e-session');
  },
  set emailSession(value) {
    const options = {maxAge: dayToSecond(SESSION_EXPIRES)};
    setEncryptCookie('e-session', value, options);
  },
};

export default Cookies;
