import React from 'react';
import {Redirect} from 'react-router-dom';
import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const dataManagement = {
  path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.INDEX,
  permission: PERMISSIONS.DATA_MANAGEMENT.ACCESS,
  component: Loadable(() => import('../views/data-management')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.INDEX,
      component: () => <Redirect to="/data-management/file-upload" />,
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.FILE_UPLOAD,
      component: Loadable(() => import('../views/data-management/file-upload')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.API_ACCESS,
      permission: PERMISSIONS.DATA_MANAGEMENT.API_ACCESS.VIEW,
      component: Loadable(() => import('../views/data-management/api')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.FTP_ACCESS,
      component: Loadable(() => import('../views/data-management/ftp-access')),
    },
    // To be implemented in CIN is already there
    {
      path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS.INDEX,
      permission: PERMISSIONS.DATA_MANAGEMENT.DATA_MAPPING.VIEW,
      component: Loadable(() =>
        import('../views/data-management/import-settings')
      ),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS.INDEX,
          permission: PERMISSIONS.DATA_MANAGEMENT.DATA_MAPPING.VIEW,
          component: () => (
            <Redirect to="/data-management/import-settings/mapping" />
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS
            .DATA_MAPPING,
          permission: PERMISSIONS.DATA_MANAGEMENT.DATA_MAPPING.VIEW,
          component: Loadable(() =>
            import('../views/data-management/import-settings/mapping')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS
            .FIELD_MAPPING,
          permission: PERMISSIONS.DATA_MANAGEMENT.FIELD_MAPPING.VIEW,
          component: Loadable(() =>
            import('../views/data-management/import-settings/field')
          ),
        },

        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS
            .DATE_FORMAT_SETTINGS,
          permission: PERMISSIONS.DATA_MANAGEMENT.DATE_FORMAT_MAPPING.VIEW,
          component: Loadable(() =>
            import('../views/data-management/import-settings/date-mapping')
          ),
        },

        {
          path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS
            .CE_NAME_MAPPING.INDEX,
          permission: PERMISSIONS.DATA_MANAGEMENT.CE_MAPPING.VIEW,
          component: Loadable(() =>
            import('../views/data-management/import-settings/ce-mapping')
          ),
          routes: [
            {
              exact: true,
              permission: PERMISSIONS.DATA_MANAGEMENT.CE_MAPPING.VIEW,
              path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS
                .CE_NAME_MAPPING.LIST,
              component: Loadable(() =>
                import(
                  '../views/data-management/import-settings/ce-mapping/list'
                )
              ),
            },
            {
              exact: true,
              permission: PERMISSIONS.DATA_MANAGEMENT.CE_MAPPING.VIEW,
              path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS
                .CE_NAME_MAPPING.SETTINGS,
              component: Loadable(() =>
                import(
                  '../views/data-management/import-settings/ce-mapping/settings'
                )
              ),
            },
          ],
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.IMPORT_SETTINGS.CIN,
          component: Loadable(() =>
            import('../views/data-management/import-settings/cin')
          ),
        },
      ],
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.DATA_MANAGEMENT.ACTIVITY,
      permission: PERMISSIONS.DATA_MANAGEMENT.ACTIVITY.VIEW,
      component: Loadable(() => import('../views/data-management/activity')),
    },
  ],
};

export default dataManagement;
