import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'constants/routes';
import {PERMISSIONS} from 'constants/permissions';

const certificationBodies = {
  path: ROUTE_PATH.DASHBOARD.CBS.INDEX,
  permission: PERMISSIONS.CBS.ACCESS,
  component: Loadable(() => import('../views/certification-bodies')),
  routes: [
    // List
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.LIST,
      component: Loadable(() => import('../views/certification-bodies/list')),
    },

    // Create
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.CREATE,
      permission: PERMISSIONS.CBS.OVERVIEW.ADD,
      component: Loadable(() => import('../views/certification-bodies/create')),
    },

    // Analytics
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.ANALYTICS,
      permission: PERMISSIONS.CBS.ANALYTICS,
      component: Loadable(() =>
        import('../views/certification-bodies/analytics')
      ),
    },

    // Activity
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.CBS.ACTIVITY,
      permission: PERMISSIONS.CBS.ACTIVITY,
      component: Loadable(() =>
        import('../views/certification-bodies/activity')
      ),
    },

    // Views
    {
      path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.INDEX,
      component: Loadable(() => import('../views/certification-bodies/views')),
      routes: [
        // Overview
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.VIEW,
          component: Loadable(() =>
            import('../views/certification-bodies/views/view')
          ),
        },

        // Edit
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.EDIT,
          permission: PERMISSIONS.CBS.VIEWS.EDIT,
          component: Loadable(() =>
            import('../views/certification-bodies/views/edit')
          ),
        },

        // Standards
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.STANDARDS,
          permission: PERMISSIONS.CBS.VIEWS.STANDARDS_SCHEMES.VIEW,
          component: Loadable(() =>
            import('../views/certification-bodies/views/standards')
          ),
        },

        // Technical Sectors
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.SECTORS,
          permission: PERMISSIONS.CBS.VIEWS.TECHNICAL_SECTORS.VIEW,
          component: Loadable(() =>
            import('../views/certification-bodies/views/sectors')
          ),
        },

        // Office Directories
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.DIRECTORIES,
          permission: PERMISSIONS.CBS.VIEWS.OFFICE_DIRECTORIES.VIEW,
          component: Loadable(() =>
            import('../views/certification-bodies/views/office-directories')
          ),
        },

        // Countries
        /*
        // Removed, 16 Sep 2021
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.COUNTRIES,
          component: Loadable(() =>
            import('../views/certification-bodies/views/countries')
          ),
        },*/

        // Certified Entities
        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/certified-entities')
          ),
          permission: PERMISSIONS.CES.ACCESS,
          routes: [
            // Certified Entities - List
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CES.LIST,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certified-entities/list'
                )
              ),
            },
          ],
        },

        {
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.INDEX,
          component: Loadable(() =>
            import('../views/certification-bodies/views/certifications')
          ),
          permission: PERMISSIONS.CERTS.ACCESS,

          routes: [
            // Certifications - List
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.LIST,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certifications/list'
                )
              ),
            },

            // Certifications - Create
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.CERTS.CREATE,
              component: Loadable(() =>
                import(
                  '../views/certification-bodies/views/certifications/create'
                )
              ),
            },
          ],
        },

        // Analytics
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.CBS.VIEWS.ANALYTICS,
          permission: PERMISSIONS.CBS.VIEWS.ANALYTICS,
          component: Loadable(() =>
            import('../views/certification-bodies/views/analytics')
          ),
        },
      ],
    },
  ],
};

export default certificationBodies;
