import CryptoJS from 'crypto-js';

export function encrypt(value, secret) {
  try {
    const string = JSON.stringify(value);
    return CryptoJS.AES.encrypt(string, secret);
  } catch (err) {
    return undefined;
  }
}

export function decrypt(value, secret) {
  try {
    const bytes = CryptoJS.AES.decrypt(value, secret);
    const string = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(string);
  } catch (err) {
    return undefined;
  }
}
