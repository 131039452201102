import Loadable from 'components/Loadable';
import {ROUTE_ACCESS_RULES} from '../configs';

const login = {
  path: '/login',
  component: Loadable(() => import('../views/login')),
  routes: [
    {
      exact: true,
      access: ROUTE_ACCESS_RULES.guest,
      path: '/login',
      component: Loadable(() => import('../views/login/default')),
    },
    {
      exact: true,
      access: ROUTE_ACCESS_RULES.public,
      path: '/login/session/:sessionId',
      component: Loadable(() => import('../views/login/session')),
    },
  ],
};

export default login;
