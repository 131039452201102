import {sendConfirmEmail} from 'api/accounts';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useResendEmail(callback) {
  const [fetch, isLoading] = useFetch(sendConfirmEmail);
  const {alertByError} = useAlert();

  async function resend() {
    try {
      await fetch();
      callback();
    } catch (e) {
      await alertByError(e);
    }
  }

  return {isLoading, resend};
}

export default useResendEmail;
