import {putAuthConfirmation} from 'api/auth';
import {useAuth} from 'contexts/AuthContext';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useVerification() {
  const {updateAccount} = useAuth();
  const [fetch, isLoading] = useFetch(putAuthConfirmation);
  const {alertByError} = useAlert();

  async function verify(formData) {
    try {
      const {data} = await fetch(formData);

      updateAccount({
        account: {account_confirm_email: data ? 1 : 0},
      });
    } catch (e) {
      await alertByError(e);
    }
  }

  return {isLoading, verify};
}

export default useVerification;
