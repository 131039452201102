import Loadable from 'components/Loadable';

// Activate by company ID
const activate = {
  exact: true,
  path: '/act/:companyId',

  component: Loadable(() => import('../views/register/activate-id')),
};

export default activate;
