import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const settings = {
  path: ROUTE_PATH.DASHBOARD.SETTINGS.INDEX,
  // AB undefined CB true or false
  permission: PERMISSIONS.SETTINGS.ACCESS,
  component: Loadable(() => import('../views/settings')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SETTINGS.USER,
      component: Loadable(() => import('../views/settings/user-profile')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.SETTINGS.SECURITY.INDEX,
      component: Loadable(() => import('../views/settings/security')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.SETTINGS.SECURITY.OVERVIEW,
          component: Loadable(() =>
            import('../views/settings/security/overview')
          ),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.SETTINGS.SECURITY.DEVICE,
          component: Loadable(() =>
            import('../views/settings/security/device')
          ),
        },
      ],
    },
    // {
    //   exact: true,
    //   path: ROUTE_PATH.DASHBOARD.SETTINGS.DATA,
    //   component: Loadable(() => import('../views/settings/data')),
    // },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SETTINGS.NOTIFICATIONS,
      component: Loadable(() => import('../views/settings/notification')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SETTINGS.DATA_SYNC,
      component: Loadable(() => import('../views/settings/data-sync')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SETTINGS.INACTIVITY_TIMEOUT,
      component: Loadable(() => import('../views/settings/inactivity-timeout')),
    },

    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SETTINGS.ACCOUNT,
      permission: PERMISSIONS.SETTINGS.BRANCH.VIEW,
      component: Loadable(() => import('../views/settings/account')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.SETTINGS.EMERGENCY_SITE,
      component: Loadable(() => import('../views/settings/emergency-site')),
    },
  ],
};

export default settings;
