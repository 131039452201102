import Loadable from 'components/Loadable';

const policies = {
  path: '/policies',
  component: Loadable(() => import('../views/policies')),
  routes: [
    {
      exact: true,
      path: '/policies/terms',
      component: Loadable(() => import('../views/policies/terms')),
    },
    {
      exact: true,
      path: '/policies/privacy',
      component: Loadable(() => import('../views/policies/privacy')),
    },
  ],
};

export default policies;
