import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {Icon} from 'components/Icons';
import {TextLoading} from 'components/Loadings';
import {getTimestamp} from 'utils/dates';

function LandingSearchHelper(props) {
  const {dark, isLoading, data} = props;

  if (isLoading)
    return (
      <span className={dark ? 'text-white-70' : 'text-body'}>
        <TextLoading isLoading />
      </span>
    );

  const {max: dataMax, try: dataTry, ttl: dataReset} = data || {};
  const resetCount = parseInt((dataReset - getTimestamp()) / 86400);

  return (
    <p className="mt-1 mb-0">
      <Icon type="fa" icon="exclamation-circle" className="text-warning mr-1" />
      Searches used:
      <span
        className={cx('font-weight-bold ml-1', {
          'text-light': dark,
          'text-dark': !dark,
        })}
      >
        {`${dataTry}/${dataMax}`}
      </span>
      {!!dataReset && (
        <span className="ml-1">(limit restarts in {resetCount} days)</span>
      )}
      <span className={cx({'text-white-70': dark})}>
        . Want to increase your search limit? Contact us at{' '}
        <Link to="/contact">UKAS Support</Link>
      </span>
    </p>
  );
}

export default LandingSearchHelper;
