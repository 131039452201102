import {postAccountOTPVerify} from 'api/accounts';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useVerifyOTP(callback) {
  const [fetch, isVerifying] = useFetch(postAccountOTPVerify, {
    initialLoading: false,
  });
  const {alertByError} = useAlert();

  const verifyOTP = async (formData) => {
    try {
      const {data} = await fetch(formData);

      if (callback) callback(data);
    } catch (e) {
      await alertByError(e);
    }
  };

  return {isVerifying: isVerifying, verifyOTP};
}

export default useVerifyOTP;
