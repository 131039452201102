import Loadable from 'components/Loadable';
import {ROUTE_ACCESS_RULES} from '../configs';
import {ROUTE_PATH} from 'constants/routes';

const demo = {
  path: ROUTE_PATH.DEMO.INDEX,
  component: Loadable(() => import('../views/demo')),
  routes: [
    {
      exact: true,
      access: ROUTE_ACCESS_RULES.public,
      path: ROUTE_PATH.DEMO.CAPTCHA,
      component: Loadable(() => import('../views/demo/captcha')),
    },
  ],
};

export default demo;
