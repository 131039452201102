import {updateAccountPasswordSkip} from 'api/accounts';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useChangePassword(callback) {
  const {alertByError} = useAlert();

  const [fetch, isLoading] = useFetch(updateAccountPasswordSkip, {
    initialLoading: false,
  });

  async function handleSkipPassword() {
    try {
      const {data} = await fetch();
      if (data && callback) callback(data);
    } catch (e) {
      await alertByError(e);
    }
  }

  return {isSkipping: isLoading, skipPassword: handleSkipPassword};
}

export default useChangePassword;
