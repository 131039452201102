import {ROUTE_PATH} from 'constants/routes';
import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'constants/permissions';

const users = {
  path: ROUTE_PATH.DASHBOARD.USERS.INDEX,
  permission: PERMISSIONS.USERS.ACCESS,
  component: Loadable(() => import('../views/users')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.USERS.LIST,
      component: Loadable(() => import('../views/users/lists')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.USERS.VIEWS.EDIT,
      permission: PERMISSIONS.USERS.EDIT,
      component: Loadable(() => import('../views/users/edit')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.USERS.ROLES.INDEX,
      permission: PERMISSIONS.USERS.ROLES.VIEW,
      component: Loadable(() => import('../views/users/roles')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.USERS.ROLES.LIST,
          component: Loadable(() => import('../views/users/roles/list')),
        },
        {
          path: ROUTE_PATH.DASHBOARD.USERS.ROLES.CREATE,
          permission: PERMISSIONS.USERS.ROLES.ADD,
          component: Loadable(() => import('../views/users/roles/create')),
        },
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.USERS.ROLES.VIEWS.EDIT,
          permission: PERMISSIONS.USERS.ROLES.EDIT,
          component: Loadable(() => import('../views/users/roles/edit')),
        },
      ],
    },
  ],
};

export default users;
