import React from 'react';

import {REGEX_FEEDBACK} from 'constants/regex';

import {Form, FormGroup, Label, FormFeedback} from 'reactstrap';
import {InputPassword} from 'components/Inputs';
import {DashboardButton} from 'components/Dashboard';
import {FieldPassword} from 'components/Fields';
import {formSubmit} from 'utils/forms';
import {useState} from 'react';

function ExpiryForm(props) {
  const {isSubmitting, onSubmit} = props;
  const [inBreached, setInBreached] = useState(true);

  return (
    <Form onSubmit={formSubmit(onSubmit)} noValidate>
      <fieldset disabled={isSubmitting}>
        <FormGroup className="is-invalid">
          <Label>Old Password</Label>

          <InputPassword name="old_password" pattern={null} required />

          <FormFeedback>{REGEX_FEEDBACK.required}</FormFeedback>
        </FormGroup>

        <FieldPassword
          passwordLabel="New Password"
          passwordName="account_password"
          confirmPasswordLabel="Confirm New Password"
          confirmPasswordName="account_password_confirmation"
          setInBreached={setInBreached}
          inBreached={inBreached}
        />

        <FormGroup>
          <DashboardButton type="submit" block>
            Change password
          </DashboardButton>
        </FormGroup>
      </fieldset>
    </Form>
  );
}

export default ExpiryForm;
