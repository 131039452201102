import Loadable from 'components/Loadable';
import {ROUTE_PATH} from 'constants/routes';
import {PERMISSIONS} from 'constants/permissions';

const branches = {
  path: ROUTE_PATH.DASHBOARD.BRANCHES.INDEX,
  permission: PERMISSIONS.BRANCHES.ACCESS,
  component: Loadable(() => import('../views/branches')),
  routes: [
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.BRANCHES.LIST,
      component: Loadable(() => import('../views/branches/list')),
    },
    {
      exact: true,
      path: ROUTE_PATH.DASHBOARD.BRANCHES.CREATE,
      permission: PERMISSIONS.BRANCHES.OVERVIEW.ADD,
      component: Loadable(() => import('../views/branches/create')),
    },
    {
      path: ROUTE_PATH.DASHBOARD.BRANCHES.VIEWS.INDEX,
      component: Loadable(() => import('../views/branches/views')),
      routes: [
        {
          exact: true,
          path: ROUTE_PATH.DASHBOARD.BRANCHES.VIEWS.VIEW,
          component: Loadable(() => import('../views/branches/views/view')),
        },
        {
          path: ROUTE_PATH.DASHBOARD.BRANCHES.VIEWS.USERS.INDEX,
          permission: PERMISSIONS.USERS.ACCESS,
          component: Loadable(() => import('../views/branches/views/users')),
          routes: [
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.BRANCHES.VIEWS.USERS.LIST,
              component: Loadable(() =>
                import('../views/branches/views/users/list')
              ),
            },
            {
              exact: true,
              path: ROUTE_PATH.DASHBOARD.BRANCHES.VIEWS.USERS.VIEWS.EDIT,
              permission: PERMISSIONS.USERS.EDIT,
              component: Loadable(() =>
                import('../views/branches/views/users/edit')
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default branches;
