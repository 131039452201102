import {changePassword} from 'api/accounts';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useChangePassword(callback) {
  const {alertByError} = useAlert();

  const [fetch, isLoading] = useFetch(changePassword, {
    initialLoading: false,
  });

  async function handleChangePassword(formData, onCallback) {
    try {
      const {data} = await fetch(formData);
      if (data && callback) callback(data);
      if (onCallback) onCallback();
    } catch (e) {
      await alertByError(e);
    }
  }

  return {isChangingPassword: isLoading, changePassword: handleChangePassword};
}

export default useChangePassword;
